.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: "Work Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 87.5%;
  font-weight: 400;
  line-height: 1.5;
  color: #111112;
  text-align: left;
  background-color: #E0E0E1;
  background-image: url(https://xrpl.org/assets/img/lightmode/home-hero.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

.App-header {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-top: 0.3rem;
  margin-bottom: 2.3rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  font-family: "Space Mono", monospace;
}

.form-container {
  width: inherit;
  display: inline-block;
  padding: 20px 20px;
  flex-direction: column;
  margin-top: 50px;
  margin: 8px 0;
  border: 1px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.form-container:hover {
  transform: translate(0px, -10px)
}

.form-group {
  margin-bottom: 20px;
  flex-direction: column;
}

label {
  text-align: left;
  display: block;
  margin-bottom: .5rem;
  font-family: "Space Mono", monospace;
}

input {
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #6610f2;
  width: 100%;
  color: white;
  border: none;
  font-family: "Space Mono", monospace;
}

button:focus:not(:focus-visible) {
  outline: 0
}

button:hover {
  background-color: #a06ef2;
}